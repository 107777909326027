:i<template>
  <!--头部-->
  <Header></Header>
  <!--内容区-->
  <div class="main">
    <div class="main_index">
      <div class="list_nav">
        <div class="list_nav_m">
          <dd class="on">
            <a href="">For You</a>
          </dd>
          <dd>
            <a href="">Fashion</a>
          </dd>
          <dd>
            <a href="">Beauty</a>
          </dd>
          <dd>
            <a href="">Food</a>
          </dd>
          <dd>
            <a href="">Wellness</a>
          </dd>
          <dd>
            <a href="">Travel</a>
          </dd>
          <dd>
            <a href="#/index">Home</a>
          </dd>
        </div>
      </div>
      <div class="listdata">
        <ul>
          <li>
            <a href="">
              <div class="listdata_bka">
                <div class="listdata_bka_img">
                  <img src="../assets/images/cpimg.jpg" alt="">
                </div>
                <div class="listdata_info">
                  <div class="listdata_info_title">
                    ONE thing to never do on Lemon8: MASS COMPLIMENT?
                  </div>
                  <div class="listdata_info_p">
                   ✨ hey all, I’m staying out of the ‘aesthetic versus non-aesthetic posting’ posting because I enjoy both, genuinely. I’m not much aesthetic myself but I like when others post content that looks pretty, and I also really enjoy that the app is seeming to welcome the less curated side of life! It really
                  </div>
                </div> 
              </div> 
              <div class="listdata_bkb">
                <div class="listdata_bkb_user">
                    <img src="../assets/images/user.jpg" alt="">
                    <p>talítha 🕊</p>
                </div>
                <div class="listdata_bkb_partition"></div>
                <div class="listdata_bkb_browse">
                  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 9.371C2 5.703 4.574 3 7.955 3c1.7 0 3.08.754 4.042 1.929C12.963 3.752 14.331 3 16.036 3 19.415 3 22 5.701 22 9.371c0 2.18-.922 4.284-2.47 6.24-1.545 1.955-3.745 3.802-6.386 5.488l-.002.001-.403-.632.408.63h-.003c-.151.099-.33.192-.508.263a1.791 1.791 0 01-.64.139c-.245 0-.476-.074-.64-.14a3.27 3.27 0 01-.5-.255l-.008-.005c-2.642-1.687-4.84-3.534-6.384-5.49C2.92 13.655 2 11.55 2 9.372zM7.955 4.5C5.485 4.5 3.5 6.446 3.5 9.371c0 1.741.734 3.527 2.141 5.31 1.408 1.783 3.46 3.523 6.01 5.152.085.053.18.101.262.134a.782.782 0 00.083.029 1.47 1.47 0 00.335-.157l.004-.003c2.553-1.63 4.609-3.372 6.02-5.155 1.41-1.784 2.145-3.57 2.145-5.31 0-2.923-1.993-4.871-4.464-4.871-1.5 0-2.65.8-3.384 2.13a.75.75 0 01-1.316-.004C10.624 5.312 9.457 4.5 7.955 4.5z" fill="currentColor"></path></svg>
                  <p>184 Likes</p>
                </div>
              </div> 
            </a>
          </li>
          <li>
            <a href="">
              <div class="listdata_bka style_b">
                <div class="listdata_bka_img">
                  <img src="../assets/images/cpimg.jpg" alt="">
                  <img src="../assets/images/cpimg.jpg" alt="">
                  <img src="../assets/images/cpimg.jpg" alt="">
                </div>
                <div class="listdata_info">
                  <div class="listdata_info_title">
                    ONE thing to never do on Lemon8: MASS COMPLIMENT?
                  </div>
                  <div class="listdata_info_p">
                   ✨ hey all, I’m staying out of the ‘aesthetic versus non-aesthetic posting’ posting because I enjoy both, genuinely. I’m not much aesthetic myself but I like when others post content that looks pretty, and I also really enjoy that the app is seeming to welcome the less curated side of life! It really
                  </div>
                </div> 
              </div> 
              <div class="listdata_bkb">
                <div class="listdata_bkb_user">
                    <img src="../assets/images/user.jpg" alt="">
                    <p>talítha 🕊</p>
                </div>
                <div class="listdata_bkb_partition"></div>
                <div class="listdata_bkb_browse">
                  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 9.371C2 5.703 4.574 3 7.955 3c1.7 0 3.08.754 4.042 1.929C12.963 3.752 14.331 3 16.036 3 19.415 3 22 5.701 22 9.371c0 2.18-.922 4.284-2.47 6.24-1.545 1.955-3.745 3.802-6.386 5.488l-.002.001-.403-.632.408.63h-.003c-.151.099-.33.192-.508.263a1.791 1.791 0 01-.64.139c-.245 0-.476-.074-.64-.14a3.27 3.27 0 01-.5-.255l-.008-.005c-2.642-1.687-4.84-3.534-6.384-5.49C2.92 13.655 2 11.55 2 9.372zM7.955 4.5C5.485 4.5 3.5 6.446 3.5 9.371c0 1.741.734 3.527 2.141 5.31 1.408 1.783 3.46 3.523 6.01 5.152.085.053.18.101.262.134a.782.782 0 00.083.029 1.47 1.47 0 00.335-.157l.004-.003c2.553-1.63 4.609-3.372 6.02-5.155 1.41-1.784 2.145-3.57 2.145-5.31 0-2.923-1.993-4.871-4.464-4.871-1.5 0-2.65.8-3.384 2.13a.75.75 0 01-1.316-.004C10.624 5.312 9.457 4.5 7.955 4.5z" fill="currentColor"></path></svg>
                  <p>184 Likes</p>
                </div>
              </div> 
            </a>
          </li>
          <li>
            <a href="">
              <div class="listdata_bka">
                <div class="listdata_bka_img">
                  <img src="../assets/images/cpimg.jpg" alt="">
                  <div class="listdata_bka_video">
                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.185 20c.385 0 .701-.138 1.106-.365l10.934-6.331c.79-.455 1.076-.78 1.076-1.304s-.286-.85-1.076-1.304L8.29 4.366C7.886 4.137 7.571 4 7.185 4 6.475 4 6 4.543 6 5.393v13.214C6 19.457 6.474 20 7.185 20z" fill="currentColor"></path></svg>
                  </div>
                </div>
                <div class="listdata_info">
                  <div class="listdata_info_title">
                    ONE thing to never do on Lemon8: MASS COMPLIMENT?
                  </div>
                  <div class="listdata_info_p">
                   ✨ hey all, I’m staying out of the ‘aesthetic versus non-aesthetic posting’ posting because I enjoy both, genuinely. I’m not much aesthetic myself but I like when others post content that looks pretty, and I also really enjoy that the app is seeming to welcome the less curated side of life! It really
                  </div>
                </div> 
              </div> 
              <div class="listdata_bkb">
                <div class="listdata_bkb_user">
                    <img src="../assets/images/user.jpg" alt="">
                    <p>talítha 🕊</p>
                </div>
                <div class="listdata_bkb_partition"></div>
                <div class="listdata_bkb_browse">
                  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 9.371C2 5.703 4.574 3 7.955 3c1.7 0 3.08.754 4.042 1.929C12.963 3.752 14.331 3 16.036 3 19.415 3 22 5.701 22 9.371c0 2.18-.922 4.284-2.47 6.24-1.545 1.955-3.745 3.802-6.386 5.488l-.002.001-.403-.632.408.63h-.003c-.151.099-.33.192-.508.263a1.791 1.791 0 01-.64.139c-.245 0-.476-.074-.64-.14a3.27 3.27 0 01-.5-.255l-.008-.005c-2.642-1.687-4.84-3.534-6.384-5.49C2.92 13.655 2 11.55 2 9.372zM7.955 4.5C5.485 4.5 3.5 6.446 3.5 9.371c0 1.741.734 3.527 2.141 5.31 1.408 1.783 3.46 3.523 6.01 5.152.085.053.18.101.262.134a.782.782 0 00.083.029 1.47 1.47 0 00.335-.157l.004-.003c2.553-1.63 4.609-3.372 6.02-5.155 1.41-1.784 2.145-3.57 2.145-5.31 0-2.923-1.993-4.871-4.464-4.871-1.5 0-2.65.8-3.384 2.13a.75.75 0 01-1.316-.004C10.624 5.312 9.457 4.5 7.955 4.5z" fill="currentColor"></path></svg>
                  <p>184 Likes</p>
                </div>
              </div> 
            </a>
          </li>
          <li>
            <a href="">
              <div class="listdata_bka">
                <div class="listdata_bka_img">
                  <img src="../assets/images/cpimg.jpg" alt="">
                </div>
                <div class="listdata_info">
                  <div class="listdata_info_title">
                    ONE thing to never do on Lemon8: MASS COMPLIMENT?
                  </div>
                  <div class="listdata_info_p">
                   ✨ hey all, I’m staying out of the ‘aesthetic versus non-aesthetic posting’ posting because I enjoy both, genuinely. I’m not much aesthetic myself but I like when others post content that looks pretty, and I also really enjoy that the app is seeming to welcome the less curated side of life! It really
                  </div>
                </div> 
              </div> 
              <div class="listdata_bkb">
                <div class="listdata_bkb_user">
                    <img src="../assets/images/user.jpg" alt="">
                    <p>talítha 🕊</p>
                </div>
                <div class="listdata_bkb_partition"></div>
                <div class="listdata_bkb_browse">
                  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 9.371C2 5.703 4.574 3 7.955 3c1.7 0 3.08.754 4.042 1.929C12.963 3.752 14.331 3 16.036 3 19.415 3 22 5.701 22 9.371c0 2.18-.922 4.284-2.47 6.24-1.545 1.955-3.745 3.802-6.386 5.488l-.002.001-.403-.632.408.63h-.003c-.151.099-.33.192-.508.263a1.791 1.791 0 01-.64.139c-.245 0-.476-.074-.64-.14a3.27 3.27 0 01-.5-.255l-.008-.005c-2.642-1.687-4.84-3.534-6.384-5.49C2.92 13.655 2 11.55 2 9.372zM7.955 4.5C5.485 4.5 3.5 6.446 3.5 9.371c0 1.741.734 3.527 2.141 5.31 1.408 1.783 3.46 3.523 6.01 5.152.085.053.18.101.262.134a.782.782 0 00.083.029 1.47 1.47 0 00.335-.157l.004-.003c2.553-1.63 4.609-3.372 6.02-5.155 1.41-1.784 2.145-3.57 2.145-5.31 0-2.923-1.993-4.871-4.464-4.871-1.5 0-2.65.8-3.384 2.13a.75.75 0 01-1.316-.004C10.624 5.312 9.457 4.5 7.955 4.5z" fill="currentColor"></path></svg>
                  <p>184 Likes</p>
                </div>
              </div> 
            </a>
          </li>
          <li>
            <a href="">
              <div class="listdata_bka style_b">
                <div class="listdata_bka_img">
                  <img src="../assets/images/cpimg.jpg" alt="">
                  <img src="../assets/images/cpimg.jpg" alt="">
                  <img src="../assets/images/cpimg.jpg" alt="">
                </div>
                <div class="listdata_info">
                  <div class="listdata_info_title">
                    ONE thing to never do on Lemon8: MASS COMPLIMENT?
                  </div>
                  <div class="listdata_info_p">
                   ✨ hey all, I’m staying out of the ‘aesthetic versus non-aesthetic posting’ posting because I enjoy both, genuinely. I’m not much aesthetic myself but I like when others post content that looks pretty, and I also really enjoy that the app is seeming to welcome the less curated side of life! It really
                  </div>
                </div> 
              </div> 
              <div class="listdata_bkb">
                <div class="listdata_bkb_user">
                    <img src="../assets/images/user.jpg" alt="">
                    <p>talítha 🕊</p>
                </div>
                <div class="listdata_bkb_partition"></div>
                <div class="listdata_bkb_browse">
                  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 9.371C2 5.703 4.574 3 7.955 3c1.7 0 3.08.754 4.042 1.929C12.963 3.752 14.331 3 16.036 3 19.415 3 22 5.701 22 9.371c0 2.18-.922 4.284-2.47 6.24-1.545 1.955-3.745 3.802-6.386 5.488l-.002.001-.403-.632.408.63h-.003c-.151.099-.33.192-.508.263a1.791 1.791 0 01-.64.139c-.245 0-.476-.074-.64-.14a3.27 3.27 0 01-.5-.255l-.008-.005c-2.642-1.687-4.84-3.534-6.384-5.49C2.92 13.655 2 11.55 2 9.372zM7.955 4.5C5.485 4.5 3.5 6.446 3.5 9.371c0 1.741.734 3.527 2.141 5.31 1.408 1.783 3.46 3.523 6.01 5.152.085.053.18.101.262.134a.782.782 0 00.083.029 1.47 1.47 0 00.335-.157l.004-.003c2.553-1.63 4.609-3.372 6.02-5.155 1.41-1.784 2.145-3.57 2.145-5.31 0-2.923-1.993-4.871-4.464-4.871-1.5 0-2.65.8-3.384 2.13a.75.75 0 01-1.316-.004C10.624 5.312 9.457 4.5 7.955 4.5z" fill="currentColor"></path></svg>
                  <p>184 Likes</p>
                </div>
              </div> 
            </a>
          </li>
          <li>
            <a href="">
              <div class="listdata_bka">
                <div class="listdata_bka_img">
                  <img src="../assets/images/cpimg.jpg" alt="">
                  <div class="listdata_bka_video">
                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.185 20c.385 0 .701-.138 1.106-.365l10.934-6.331c.79-.455 1.076-.78 1.076-1.304s-.286-.85-1.076-1.304L8.29 4.366C7.886 4.137 7.571 4 7.185 4 6.475 4 6 4.543 6 5.393v13.214C6 19.457 6.474 20 7.185 20z" fill="currentColor"></path></svg>
                  </div>
                </div>
                <div class="listdata_info">
                  <div class="listdata_info_title">
                    ONE thing to never do on Lemon8: MASS COMPLIMENT?
                  </div>
                  <div class="listdata_info_p">
                   ✨ hey all, I’m staying out of the ‘aesthetic versus non-aesthetic posting’ posting because I enjoy both, genuinely. I’m not much aesthetic myself but I like when others post content that looks pretty, and I also really enjoy that the app is seeming to welcome the less curated side of life! It really
                  </div>
                </div> 
              </div> 
              <div class="listdata_bkb">
                <div class="listdata_bkb_user">
                    <img src="../assets/images/user.jpg" alt="">
                    <p>talítha 🕊</p>
                </div>
                <div class="listdata_bkb_partition"></div>
                <div class="listdata_bkb_browse">
                  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 9.371C2 5.703 4.574 3 7.955 3c1.7 0 3.08.754 4.042 1.929C12.963 3.752 14.331 3 16.036 3 19.415 3 22 5.701 22 9.371c0 2.18-.922 4.284-2.47 6.24-1.545 1.955-3.745 3.802-6.386 5.488l-.002.001-.403-.632.408.63h-.003c-.151.099-.33.192-.508.263a1.791 1.791 0 01-.64.139c-.245 0-.476-.074-.64-.14a3.27 3.27 0 01-.5-.255l-.008-.005c-2.642-1.687-4.84-3.534-6.384-5.49C2.92 13.655 2 11.55 2 9.372zM7.955 4.5C5.485 4.5 3.5 6.446 3.5 9.371c0 1.741.734 3.527 2.141 5.31 1.408 1.783 3.46 3.523 6.01 5.152.085.053.18.101.262.134a.782.782 0 00.083.029 1.47 1.47 0 00.335-.157l.004-.003c2.553-1.63 4.609-3.372 6.02-5.155 1.41-1.784 2.145-3.57 2.145-5.31 0-2.923-1.993-4.871-4.464-4.871-1.5 0-2.65.8-3.384 2.13a.75.75 0 01-1.316-.004C10.624 5.312 9.457 4.5 7.955 4.5z" fill="currentColor"></path></svg>
                  <p>184 Likes</p>
                </div>
              </div> 
            </a>
          </li>
        </ul>

      </div>
      <div class="load">
        ~Load more~
      </div>
    </div>
  </div>
</template>

<script>
//公共文件 头部/底部
import Header from "@/components/public/Header.vue";


export default {
  name: 'Index',
  components: {
    Header
  },
  setup(){
			
			return{
	
			}
		},
		data(){
			
		},
		props:['active'],
		created() {

		},
		methods:{

		}
}
</script>
