import { createRouter,  createWebHistory,createWebHashHistory} from 'vue-router'
import Index from '../views/Index.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: { title: 'Home'},
  },
  {
    path: '/show',
    name: 'show',
    component: () => import('../views/Show.vue'),
    meta: { title: 'Show'},
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/Search.vue'),
    meta: { title: 'Search'},
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/User.vue'),
    meta: { title: 'User'},
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

//动态修改页面title
router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title
  next()
})

export default router
