<template>
    <header>
        <div class="headTop">
            <div class="headTop_left">
                <a class="headTop_logo" href=""><img src="@/assets/images/logo.png" alt=""></a>
                <p class="headTop_p">Fresh discoveries everyday</p>
            </div> 
            <div class="headTop_right">
                <div class="headTop_right_but"><a href="">Open</a></div>
            </div> 
        </div>
        <div class="headTool">
            <svg @click="headToolMenu" width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="headTool_menu"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 17.75h16a.75.75 0 010 1.5H4a.75.75 0 010-1.5zm0-6.5h16a.75.75 0 010 1.5H4a.75.75 0 010-1.5zM20.75 5.5a.75.75 0 01-.75.75H4a.75.75 0 110-1.5h16a.75.75 0 01.75.75z" fill="currentColor"></path></svg>
            <p class="headTool_title">Lemon8</p>
            <a class="headTool_title_search" href="#/search">
                <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.347 17.408a8.5 8.5 0 111.06-1.06l3.983 3.982a.75.75 0 01-1.06 1.06l-3.983-3.982zm1.543-6.518a7 7 0 11-14 0 7 7 0 0114 0z" fill="currentColor"></path></svg>
            </a>
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="headTool_share"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 8.677l-1.252.21a10.509 10.509 0 00-8.606 8.624 13.457 13.457 0 018.235-3.723l1.623-.134v4.197l7.27-6.61-7.27-6.609v4.045zM3.52 19.884a11.697 11.697 0 00-.534.743c-.26.392-.878.296-.925-.172A12.098 12.098 0 012 19.24C2 13.295 6.325 8.36 12 7.407V3.502c0-.868 1.03-1.324 1.673-.74l8.513 7.74a1 1 0 010 1.48l-8.513 7.739c-.643.584-1.673.128-1.673-.74v-3.699a12 12 0 00-8.48 4.602z" fill="currentColor"></path></svg>
        </div>
    </header>
    <div class="wap_nav">
        <div class="wap_nav_content">
            <div class="wap_nav_content_m">
                <div class="wap_nav_content_top">
                    <ul class="nav-main-list">
                        <li class="nav-main-list-item">
                            <div class="title">
                                <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.86 8.193l-7.83-4.27-7.828 4.27 7.829 4.034 7.829-4.034zm-7.111-5.587a1.5 1.5 0 00-1.437 0l-7.829 4.27c-1.056.576-1.038 2.1.032 2.65l7.829 4.034a1.5 1.5 0 001.374 0l7.829-4.034c1.07-.55 1.087-2.074.031-2.65l-7.829-4.27zm8.422 9.76a.75.75 0 01-.337 1.005l-7.803 3.883a2.25 2.25 0 01-2 .003l-7.863-3.885a.75.75 0 01.664-1.345l7.864 3.885a.75.75 0 00.666 0l7.804-3.884a.75.75 0 011.005.338zm-.337 5.005a.75.75 0 10-.668-1.342l-7.804 3.882a.75.75 0 01-.666.001l-7.864-3.884a.75.75 0 00-.664 1.344l7.864 3.885c.63.312 1.37.31 1.999-.003l7.803-3.883z" fill="currentColor"></path>
                                </svg>
                                <span>Category</span>
                            </div>
                            <ul class="content">
                                <li><a href="">For You</a></li>
                                <li><a href="">Fashion</a></li>
                                <li><a href="">Beauty</a></li>
                                <li><a href="">Food</a></li>
                                <li><a href="">Wellness</a></li>
                                <li><a href="">Travel</a></li>
                                <li><a href="">Home</a></li>
                            </ul>
                        </li>
                        <li class="nav-main-list-item">
                            <div class="title">
                                <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.25a.75.75 0 00-.75.75v11.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V3a.75.75 0 00-.75-.75zM19.5 19V7a.5.5 0 00-.5-.5h-4V5h4a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h4v1.5H5a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h14a.5.5 0 00.5-.5z" fill="currentColor"></path>
                                </svg>
                                <span>App version</span>
                            </div>
                        </li>
                        <div class="divider"></div>
                        <li class="nav-main-list-item">
                            <a class="title" href="mailto:contact@lemon8-app.com">
                                <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 6a2 2 0 012-2h16a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V6zm17.32-.5H4.68L12 11.528 19.32 5.5zM3.5 6.472V18a.5.5 0 00.5.5h16a.5.5 0 00.5-.5V6.472l-7.705 6.345a1.25 1.25 0 01-1.59 0L3.5 6.472z" fill="currentColor"></path>
                                </svg>
                                <span>Help</span>
                            </a>
                        </li>
                        <div class="divider"></div>
                        <li class="nav-main-list-item region-switcher">
                            <div class="lang_list">
                                <div class="lang_select" @click="langselect">
                                    <div class="lang_select_nr">
                                        <i class="icon_bk" :class="classico"></i>
                                        <span>{{country}}</span>   
                                    </div>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.563 16.014a.5.5 0 00.793.002l4.409-5.71a.5.5 0 00-.396-.806H7.6a.5.5 0 00-.398.803l4.362 5.711z" fill="currentColor"></path></svg>
                                </div>
                                <div class="lang_select_gj">
                                    <dd :class="countryid==1?'on':''" @click="langFun('icon-jp','日本')">
                                        <div class="lang_select_nr">
                                            <i class="icon_bk icon-jp"></i>
                                            <span>日本</span>   
                                        </div>
                                        <div class="lang_select_icos">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="checkmark"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.36 4.838a.9.9 0 01.052 1.272l-11.75 12.75a.9.9 0 01-1.323 0l-6-6.5a.9.9 0 111.322-1.22L10 16.922 21.088 4.89a.9.9 0 011.272-.052z" fill="currentColor"></path></svg>
                                        </div>
                                    </dd>
                                    <dd :class="countryid==2?'on':''" @click="langFun('icon-th','ไทย')">
                                        <div class="lang_select_nr">
                                            <i class="icon_bk icon-th"></i>
                                            <span>ไทย</span>   
                                        </div>
                                        <div class="lang_select_icos">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="checkmark"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.36 4.838a.9.9 0 01.052 1.272l-11.75 12.75a.9.9 0 01-1.323 0l-6-6.5a.9.9 0 111.322-1.22L10 16.922 21.088 4.89a.9.9 0 011.272-.052z" fill="currentColor"></path></svg>
                                        </div>
                                    </dd>
                                    <dd :class="countryid==3?'on':''" @click="langFun('icon-id','Indonesia')">
                                        <div class="lang_select_nr">
                                            <i class="icon_bk icon-id"></i>
                                            <span>Indonesia</span>   
                                        </div>
                                        <div class="lang_select_icos">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="checkmark"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.36 4.838a.9.9 0 01.052 1.272l-11.75 12.75a.9.9 0 01-1.323 0l-6-6.5a.9.9 0 111.322-1.22L10 16.922 21.088 4.89a.9.9 0 011.272-.052z" fill="currentColor"></path></svg>
                                        </div>
                                    </dd>
                                    <dd :class="countryid==4?'on':''" @click="langFun('icon-ph','Philippines')">
                                        <div class="lang_select_nr">
                                            <i class="icon_bk icon-ph"></i>
                                            <span>Philippines</span>   
                                        </div>
                                        <div class="lang_select_icos">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="checkmark"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.36 4.838a.9.9 0 01.052 1.272l-11.75 12.75a.9.9 0 01-1.323 0l-6-6.5a.9.9 0 111.322-1.22L10 16.922 21.088 4.89a.9.9 0 011.272-.052z" fill="currentColor"></path></svg>
                                        </div>
                                    </dd>
                                    <dd :class="countryid==5?'on':''" @click="langFun('icon-vn','Việt Nam')">
                                        <div class="lang_select_nr">
                                            <i class="icon_bk icon-vn"></i>
                                            <span>Việt Nam</span>   
                                        </div>
                                        <div class="lang_select_icos">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="checkmark"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.36 4.838a.9.9 0 01.052 1.272l-11.75 12.75a.9.9 0 01-1.323 0l-6-6.5a.9.9 0 111.322-1.22L10 16.922 21.088 4.89a.9.9 0 011.272-.052z" fill="currentColor"></path></svg>
                                        </div>
                                    </dd>
                                    <dd :class="countryid==6?'on':''" @click="langFun('icon-my','Malaysia')">
                                        <div class="lang_select_nr">
                                            <i class="icon_bk icon-my"></i>
                                            <span>Malaysia</span>   
                                        </div>
                                        <div class="lang_select_icos">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="checkmark"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.36 4.838a.9.9 0 01.052 1.272l-11.75 12.75a.9.9 0 01-1.323 0l-6-6.5a.9.9 0 111.322-1.22L10 16.922 21.088 4.89a.9.9 0 011.272-.052z" fill="currentColor"></path></svg>
                                        </div>
                                    </dd>
                                    <dd :class="countryid==7?'on':''" @click="langFun('icon-sg','Singapore')">
                                        <div class="lang_select_nr">
                                            <i class="icon_bk icon-sg"></i>
                                            <span>Singapore</span>   
                                        </div>
                                        <div class="lang_select_icos">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="checkmark"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.36 4.838a.9.9 0 01.052 1.272l-11.75 12.75a.9.9 0 01-1.323 0l-6-6.5a.9.9 0 111.322-1.22L10 16.922 21.088 4.89a.9.9 0 011.272-.052z" fill="currentColor"></path></svg>
                                        </div>
                                    </dd>
                                    <dd :class="countryid==8?'on':''" @click="langFun('icon-gb','UK')">
                                        <div class="lang_select_nr">
                                            <i class="icon_bk icon-gb"></i>
                                            <span>UK</span>   
                                        </div>
                                        <div class="lang_select_icos">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="checkmark"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.36 4.838a.9.9 0 01.052 1.272l-11.75 12.75a.9.9 0 01-1.323 0l-6-6.5a.9.9 0 111.322-1.22L10 16.922 21.088 4.89a.9.9 0 011.272-.052z" fill="currentColor"></path></svg>
                                        </div>
                                    </dd>
                                    <dd :class="countryid==9?'on':''" @click="langFun('icon-us','US')">
                                        <div class="lang_select_nr">
                                            <i class="icon_bk icon-us"></i>
                                            <span>US</span>   
                                        </div>
                                        <div class="lang_select_icos">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="checkmark"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.36 4.838a.9.9 0 01.052 1.272l-11.75 12.75a.9.9 0 01-1.323 0l-6-6.5a.9.9 0 111.322-1.22L10 16.922 21.088 4.89a.9.9 0 011.272-.052z" fill="currentColor"></path></svg>
                                        </div>
                                    </dd>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="wap_nav_content_botmmon">
                    <a href="" target="_blank">Privacy Policy</a>
                    <a href="" target="_blank">Terms of Service</a>
                    <a href="" target="_blank">Cookies Policy</a>
                </div>
            </div>
        </div>
    </div>
    <div class="wap_nav_layer" @click="headToolClose"></div>
</template>
<script>
import {ref} from "vue"
//jquery.js
import $ from 'jquery'
	export default{
        name:'Header',
		setup(){
            const country = ref('US'); //国家名称
            const classico = ref('icon-us'); //国旗
            const langFun = (className,countryName)=> {
                classico.value = className;
                country.value = countryName;
            }
			
			return{
                country,
                classico,
                langFun
	
			}
		},
		data(){
            return{
                countryid:9, //国家ID

            }
		},
		props:[],
		created() {

		},
		methods:{
            headToolMenu(){
                $('.wap_nav_layer').addClass('on');
                $('.wap_nav').addClass('on');
            },
            headToolClose(){
                $('.wap_nav_layer').removeClass('on');
                $('.wap_nav').removeClass('on');
            },
            //国家下拉
            langselect(){
                if($('.lang_select_gj').is(':hidden')){
                    $('.lang_select_gj').show();
                }else{
                    $('.lang_select_gj').hide();
                }
            },
            

		}
	
	}
</script>