import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入第三方图标库
import '@/assets/fonticon/iconfont.css'

//引入swiper样式
import "swiper/swiper-bundle.css"

//引入公共样式
import '@/assets/css/public.css'



createApp(App).use(store).use(router).mount('#app')
